'use client';

import isPropValid from '@emotion/is-prop-valid';
import { createClient } from '@hey-api/client-fetch';
import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { WebTarget } from 'styled-components/dist/types';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';

import ToastContainer from '@Components/atoms/ToastContainer/ToastContainer';
import { MyGlobalContext } from '@Components/context/GlobalContext';
import { useGlobalContextHelper } from '@Components/context/useGlobalContextHelper';
import NotificationToastContainer from '@Components/molecules/NotificationToastContainer';

import { getDefaultTheme } from '@Assets/styles/theme/theme';

interface Props {
  lng: string;
  user?: string;
  authorization?: string;
  children: React.ReactNode;
}

const ContextAndThemeProvider = (props: Props): React.ReactElement => {
  const { children, lng, user, authorization } = props;

  const userModel = user && authorization ? LocalStorageUserModel.hydrateFromJson(user, authorization) : undefined;

  createClient({
    baseUrl: process.env.NEXT_PUBLIC_API_ROOT_URL ?? '',
    global: true,
  });

  const globalContext = useGlobalContextHelper(lng, userModel);

  const theme = getDefaultTheme(globalContext.currentTheme);

  // todo: tmp solution => we should migrate to transient props (https://styled-components.com/docs/api#transient-props).
  //  Visit https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default for more information
  const shouldForwardProp = (prop: string, target: WebTarget) => {
    if (typeof target === 'string') {
      return isPropValid(prop);
    }
    return true;
  };

  return (
    <MyGlobalContext.Provider value={globalContext}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme={theme}>
          {children}
          <NotificationToastContainer>
            <ToastContainer />
          </NotificationToastContainer>
        </ThemeProvider>
      </StyleSheetManager>
    </MyGlobalContext.Provider>
  );
};

export { ContextAndThemeProvider };
