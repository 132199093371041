'use client';

import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18nConfig from '@Root/i18nConfig';
import localazyMeta from '@Root/localazy-meta';
import { setCookieTranslation } from '@Root/src/api-next/cookie';

import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';

import { TextColor, TextSize } from '@Helpers/types/text';

import { Root, StyledRow } from './LanguageSwitcher.styles';

interface Props extends PropsWithClassName {}

const LanguageSwitcher = (props: Props): React.ReactElement => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const pathname = usePathname();

  const currentLocale = i18n.language;

  const handleLanguageChange = async (lang: string) => {
    // set cookie in case of missing locale
    await setCookieTranslation(lang);

    if (i18nConfig.locales.some((lng) => pathname?.startsWith(`/${lng}`))) {
      router.push(pathname?.replace(`/${currentLocale}`, `/${lang}`) ?? '');

      return;
    }

    router.push(`/${lang}` + pathname);
  };

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'language-switcher-root'}>
      {localazyMeta.languages.map(({ language, name, localizedName }) => (
        <div key={language} onClick={() => handleLanguageChange(language)}>
          <StyledRow noGutters xsEnd dataTestId={language + '-root'}>
            <Text
              size={TextSize.Medium}
              bold
              color={i18n?.language === language ? TextColor.ThemePrimary : TextColor.Header}
              data-test-id={`core.change-language-item-${language}`}
            >
              {name}
            </Text>
            <Text
              size={TextSize.Medium}
              color={i18n?.language === language ? TextColor.ThemePrimary : TextColor.Header}
              data-test-id={`core.change-language-item-${language}`}
            >
              &nbsp;{`(${localizedName})`}
            </Text>
          </StyledRow>
        </div>
      ))}
    </Root>
  );
};

export default LanguageSwitcher;
