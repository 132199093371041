import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropsWithClassName } from '@Components/helper';
import Logo, { LogoVariant } from '@Components/molecules/Logo/Logo';

import { TextSize } from '@Helpers/types/text';

import { Root, StyledText } from './FooterMessage.styles';

interface Props extends PropsWithClassName {}

const FooterMessage = (props: Props): React.ReactElement => {
  const { t } = useTranslation('global');

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'footer-message'}>
      <Logo variant={LogoVariant.Logo} height={32} />
      <StyledText size={TextSize.Xs}>{t('footer.message')}</StyledText>
    </Root>
  );
};

export default FooterMessage;
