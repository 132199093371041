import { ModFlaggedUserListMetaResponse } from '@Root/src/api-generated';

// todo: replace with generated enum from backend as soon as native enums are supported in OpenApi
export enum ModUserListLimit {
  _20 = 20,
  _50 = 50,
  _100 = 100,
}

export enum ModUserListSortBy {
  Username = 'username',
  ShortId = 'shortId',
  AccountType = 'accountType',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export enum ModFlagFilter {
  Spammer = 'spammer',
  Cheater = 'cheater',
  NonCompliantProfile = 'nonCompliantProfile',
  NonCompliantUsername = 'nonCompliantUsername',
}

export default class ModFlaggedUserListMetaModel implements ModFlaggedUserListMetaResponse {
  public page: number;
  public limit: ModUserListLimit;
  public total: number;
  public totalPages: number;
  public sortBy: ModUserListSortBy;
  public sortDirection: string;
  public query: string | null;
  public modFlagFilter: ModFlagFilter | null;

  constructor(
    page: number,
    limit: ModUserListLimit,
    total: number,
    totalPages: number,
    sortBy: ModUserListSortBy,
    sortDirection: string,
    query: string | null,
    modFlagFilter: ModFlagFilter | null
  ) {
    this.page = page;
    this.limit = limit;
    this.total = total;
    this.totalPages = totalPages;
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.query = query;
    this.modFlagFilter = modFlagFilter;
  }

  public static hydrateFromApi(modUserListMetaResponse: ModFlaggedUserListMetaResponse): ModFlaggedUserListMetaModel {
    const sortBy = ModUserListSortBy[modUserListMetaResponse.sortBy as keyof typeof ModUserListSortBy];

    const modFlagFilter = modUserListMetaResponse.modFlagFilter
      ? ModFlagFilter[modUserListMetaResponse.modFlagFilter as keyof typeof ModFlagFilter]
      : null;

    return new ModFlaggedUserListMetaModel(
      modUserListMetaResponse.page,
      modUserListMetaResponse.limit,
      modUserListMetaResponse.total,
      modUserListMetaResponse.totalPages,
      sortBy,
      modUserListMetaResponse.sortDirection,
      modUserListMetaResponse.query,
      modFlagFilter
    );
  }
}
