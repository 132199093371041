import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { MiddleLine, Root } from './MenuButton.styles';

interface Props extends PropsWithClassName {
  isOpen: boolean;
  onClick: (isOpen: boolean) => void;
}

const MenuButton = (props: Props): React.ReactElement => {
  const { className, dataTestId, isOpen, onClick } = props;

  return (
    <Root
      className={className}
      data-testid={dataTestId ?? 'menu-button-root'}
      $isOpen={isOpen}
      onClick={() => onClick(!isOpen)}
    >
      <MiddleLine $isOpen={isOpen} />
    </Root>
  );
};

export default MenuButton;
