import { IconBrandDiscordFilled, IconBrandFacebook, IconBrandX } from '@tabler/icons-react';
import React from 'react';

import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';

import { StyledLink } from './SocialIcons.styles';

const socialIcons = [
  {
    icon: <IconBrandDiscordFilled />,
    url: 'https://discord.gg/4KypVEM',
    dataTestId: 'social-icons-discord',
  },
  {
    icon: <IconBrandFacebook />,
    url: 'https://www.facebook.com/10fastfingers',
    dataTestId: 'social-icons-facebook',
  },
  {
    icon: <IconBrandX />,
    url: 'https://x.com/10fastfingers_',
    dataTestId: 'social-icons-x',
  },
];

interface Props extends PropsWithClassName {}

const SocialIcons = (props: Props): React.ReactElement => {
  return (
    <div className={props.className} data-testid={props.dataTestId ?? 'social-icons-root'}>
      <Row noGutters rowGap={RowGap.Large}>
        {socialIcons.map((socialIcon, index) => (
          <StyledLink
            size={LinkSize.Medium}
            weight={LinkWeight.Light}
            href={socialIcon.url}
            key={index}
            target="_blank"
            rel="noreferrer"
            dataTestId={socialIcon.dataTestId}
          >
            {socialIcon.icon}
          </StyledLink>
        ))}
      </Row>
    </div>
  );
};

export default SocialIcons;
