import React from 'react';
import { useTheme } from 'styled-components';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './IconMultiplayer.styles';

interface Props extends PropsWithClassName {
  size?: number;
  isHeader?: boolean;
}

const IconMultiplayer = (props: Props): React.ReactElement => {
  const { size, dataTestId, isHeader } = props;
  const theme = useTheme();

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ?? 'icon-multiplayer-root'}
      width={size ? size : 24}
      height={size ? size : 24}
      fill="none"
      viewBox={'0 0 512 512'}
      {...props}
    >
      <g
        fill="none"
        stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
        strokeWidth={22}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      >
        <path d="M193.259 419.261c-15.674 11.564-35.042 18.406-55.981 18.406s-40.307-6.843-55.981-18.406c-19.498 16.101-31.922 40.442-31.922 67.681V501H225.18v-14.057c.001-27.24-12.423-51.581-31.921-67.682zM430.702 419.261c-15.674 11.564-35.042 18.406-55.981 18.406s-40.307-6.843-55.981-18.406c-19.498 16.101-31.922 40.442-31.922 67.681V501h175.805v-14.057c.001-27.24-12.423-51.581-31.921-67.682z" />
      </g>
      <path
        d="M137.279 391.689h0c-30.844 0-55.847-25.004-55.847-55.847v-23.428c0-30.844 25.004-55.847 55.847-55.847h0c30.844 0 55.847 25.004 55.847 55.847v23.428c0 30.843-25.004 55.847-55.847 55.847zM374.721 391.689h0c-30.844 0-55.847-25.004-55.847-55.847v-23.428c0-30.844 25.004-55.847 55.847-55.847h0c30.844 0 55.847 25.004 55.847 55.847v23.428c.001 30.843-25.003 55.847-55.847 55.847zM477.545 113.554h-20.298M440.092 49.604l8.464-8.464M440.092 177.913l8.464 8.464M34.455 113.963h20.297M71.908 177.913l-8.464 8.464M71.908 49.604l-8.464-8.464"
        fill="none"
        stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
        strokeWidth={22}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M372.015 218.795h-232.03c-17.858 0-32.335-14.477-32.335-32.335V43.335C107.65 25.477 122.127 11 139.985 11h232.03c17.858 0 32.335 14.477 32.335 32.335V186.46c0 17.858-14.477 32.335-32.335 32.335z"
        fill="none"
        stroke={!isHeader ? theme.palette.icon.accent : theme.palette.icon.accentHeader}
        strokeWidth={22}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M327.25 81.583s-15.086-12.651-32.876-7.305c-16.335 4.909-18.62 23.7-6.802 31.386 0 0 11.597 5.161 24.459 9.894 30.962 11.393 17.625 41.276-7.3 41.276-12.482 0-22.957-5.453-29.298-12.433M181.972 72.961l28.745 82.614c.602 1.691 3.006 1.675 3.585-.024l27.451-82.436"
        fill="none"
        stroke={!isHeader ? theme.palette.icon.accent : theme.palette.icon.accentHeader}
        strokeWidth={22}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </Root>
  );
};

export default IconMultiplayer;
