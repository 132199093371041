import styled from 'styled-components';

import Col from '@Components/atoms/Col';
import Link from '@Components/atoms/Link';
import Text from '@Components/atoms/Text';

export const Root = styled.nav`
  margin-top: ${(props) => props.theme.space.large_32};
  margin-bottom: ${(props) => props.theme.space.default_16};
`;
// export const SectionHeadline = styled(Headline)`
//   margin-bottom: ${(props) => props.theme.space.default_16};
//   color: ${(props) => props.theme.palette.footer.textLight};
//   transition: color ${(props) => props.theme.transition.theme};
// `;

export const StyledText = styled(Text)`
  font-weight: ${(props) => props.theme.font.text.semibold.medium.fontWeight};
  color: ${(props) => props.theme.palette.footer.textLight};
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.space.default_16};

  opacity: 0.7;

  color: ${(props) => props.theme.palette.footer.text};
  transition: color ${(props) => props.theme.transition.theme};

  &:hover {
    opacity: 1;
  }
`;
