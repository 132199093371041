import { css } from 'styled-components';

export const InputStyles = (error: boolean, disabled: boolean) => css`
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.button};
  padding: ${(props) => props.theme.space.small_8} 0.75rem;

  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.light.medium.fontSize};
  font-weight: ${(props) => props.theme.font.text.light.medium.fontWeight};
  line-height: ${(props) => props.theme.font.text.light.medium.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.light.medium.letterSpacing};

  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) =>
    disabled ? props.theme.palette.input.background.disabled : props.theme.palette.input.background.default};
  border: 1px solid
    ${(props) => (error ? props.theme.palette.input.border.error : props.theme.palette.input.border.default)};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme};

  -webkit-appearance: none;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.input.border.hover};
    outline: none;
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.palette.button.primary.background};
    outline: none;
  }

  &:focus-visible {
    /* outline: none; */
  }

  ::placeholder {
    opacity: 0.6;
    color: ${(props) => props.theme.palette.text.tertiary};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
export const ErrorTextStyles = () => css`
  color: ${(props) => props.theme.palette.text.error};
  transition: color ${(props) => props.theme.transition.theme};
`;
export const UserSelectNone = () => css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
