import styled, { css } from 'styled-components';

export const Root = styled.div<{ $isOpen: boolean }>`
    display: block;
    width: 24px;
    height: 14px;
    position: relative;
    cursor: pointer;

    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 0;
        border-bottom: 2px solid ${(props) => props.theme.palette.text.header};
        width: 100%;
        left: 0;
        right: 0;
        transition: all ease-out 0.3s, border-bottom ${(props) => props.theme.transition.theme};
    }

    &:after {
        top: 100%;
    }

    ${(props) =>
      props.$isOpen &&
      css`
        &:after {
          transform: rotate(-45deg);
          transform-origin: center;
          top: 50%;
        }

        &:before {
          transform: rotate(45deg);
          transform-origin: center;
          top: 50%;
        }
      `}
}
`;

export const MiddleLine = styled.i<{ $isOpen: boolean }>`
  display: block;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  height: 2px;
  background-color: ${(props) => props.theme.palette.text.header};
  width: 100%;
  position: absolute;
  top: 50%;
  transition:
    all ease-out 0.1s,
    background-color ${(props) => props.theme.transition.theme};

  ${(props) =>
    props.$isOpen &&
    css`
      opacity: 0;
    `}
`;
