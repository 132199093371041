import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: ${(props) => props.theme.palette.popOver.background.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.light.medium.fontSize};
    font-weight: ${(props) => props.theme.font.text.light.medium.fontWeight};
    line-height: ${(props) => props.theme.font.text.light.medium.lineHeight};
    letter-spacing: ${(props) => props.theme.font.text.light.medium.letterSpacing};
    color: ${(props) => props.theme.palette.text.primary};
    padding: ${(props) => props.theme.space.xs_4};

    transition:
      color ${(props) => props.theme.transition.theme},
      background-color ${(props) => props.theme.transition.theme};
  }

  .Toastify__close-button {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
