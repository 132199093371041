import(/* webpackMode: "eager", webpackExports: ["ReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaContext","ReCaptchaProvider","useReCaptchaContext"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["withReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/styles.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ContextAndThemeProvider"] */ "/app/src/components/context/ContextAndThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/helper/StyledComponetsRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/helper/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/templates/DefaultTemplate/DefaultTemplate.tsx");
