import styled from 'styled-components';

import Button from '@Components/atoms/Button';
import Link from '@Components/atoms/Link';
import Text from '@Components/atoms/Text';
import { adjustAlpha } from '@Components/helper/styleHelper';

export const StyledButton = styled(Button)`
  display: flex;
  margin: 0;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.default_16};
  align-items: end;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.text.header};
  white-space: nowrap;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;

export const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.text.header};

  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;

export const Username = styled(Text)`
  padding-bottom: ${(props) => props.theme.space.default_16};
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: -200%;
    width: 350%;
    height: 1px;
    background-color: ${(props) => adjustAlpha(props.theme.palette.divider.header, 0.3)};
  }

  @media (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;
