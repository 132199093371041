import { ValidationErrorResponse } from '@Api-generated';

export interface ValidationMessage {
  property: string;
  message: string;
  key: string;
}

export default class ValidationErrorModel implements ValidationErrorResponse {
  public code: number;
  public data: Array<ValidationMessage>;
  public message: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(code: number, data: Array<{ property?: any; message?: any }>, message: string) {
    this.code = code;
    this.data = data.map((violation, index) => {
      return {
        property: violation.property,
        message: violation.message,
        key: `${violation.property}-${index}`,
      };
    });
    this.message = message;
  }

  public static hydrateFromApi(validationErrorResponse: ValidationErrorResponse): ValidationErrorModel {
    return new ValidationErrorModel(
      validationErrorResponse.code,
      validationErrorResponse.data,
      validationErrorResponse.message
    );
  }

  public getForField(field: string): Array<ValidationMessage> {
    return this.data.filter((violation) => {
      return violation.property === field;
    });
  }

  public getForFields(fields: string[]): Array<ValidationMessage> {
    let messages: ValidationMessage[] = [];

    fields.forEach((field) => {
      messages = [...messages, ...this.getForField(field)];
    });

    return messages;
  }

  public hasError(field: string): boolean {
    return this.getForField(field).length > 0;
  }
}
