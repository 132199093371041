import { IconLoader2 } from '@tabler/icons-react';
import styled, { keyframes } from 'styled-components';

import { ButtonModifier } from '@Components/atoms/Button/Button';

interface ButtonProps {
  readonly modifier: ButtonModifier;
  readonly $loading: boolean;
}

export const Root = styled.button<ButtonProps>`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  text-align: center;
  align-items: center;
  padding: 9px 17px 9px 17px;
  min-width: 6.25rem;
  min-height: 2.75rem;
  max-height: 2.75rem;

  background-color: ${(props) => props.theme.palette.button[props.modifier].background};
  color: ${(props) => props.theme.palette.button[props.modifier].text};
  border: 1px solid ${(props) => props.theme.palette.button[props.modifier].border};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};

  border-radius: ${(props) => props.theme.borderRadius.button};
  box-shadow: 0 1px 2px ${(props) => props.theme.palette.button.shadow};

  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.semibold.small.fontSize};
  font-weight: ${(props) => props.theme.font.text.semibold.small.fontWeight};
  line-height: ${(props) => props.theme.font.text.semibold.small.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.semibold.small.letterSpacing};

  margin-bottom: ${(props) => props.theme.space.default_16};
  justify-content: center;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &:hover {
    outline: 1px solid ${(props) => props.theme.palette.button[props.modifier].hoverBorder};
  }

  &:active {
    outline: 1px solid ${(props) => props.theme.palette.button[props.modifier].hoverBorder};
  }

  &:focus {
    outline: 2px solid ${(props) => props.theme.palette.button[props.modifier].hoverBorder};
  }
`;
export const Icon = styled.div`
  position: relative;
  margin-right: ${(props) => props.theme.space.xs_4};
  width: 24px;
  height: 24px;
`;

const rotate = keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const StyledIconLoader = styled(IconLoader2)`
  -webkit-animation: ${rotate} 1s linear infinite;
  -moz-animation: ${rotate} 1s linear infinite;
  -ms-animation: ${rotate} 1s linear infinite;
  -o-animation: ${rotate} 1s linear infinite;
  animation: ${rotate} 1s linear infinite;
`;
