import styled, { css } from 'styled-components';

import Link from '@Components/atoms/Link';
import Row from '@Components/atoms/Row';
import { slideInRight, slideOutRight } from '@Components/helper/animation';
import { adjustAlpha } from '@Components/helper/styleHelper';
import { headerHeight } from '@Components/organisms/Header/Header.styles';

export const Root = styled.div<{ $isUnmounting: boolean }>`
  ${slideInRight(0.5)}
  ${(props) =>
    props.$isUnmounting &&
    css`
      ${slideOutRight(0.5)}
      animation-fill-mode: forwards;
    `}

    position: fixed;
  top: 0;
  right: 0;
  height: 100vh;

  min-width: 13rem;
  max-width: 80vw;
  @media (min-width: ${(props) => props.theme.device.tablet}) {
    max-width: 21rem;
  }

  background-color: ${(props) => props.theme.palette.background.header};
  transition: background-color ${(props) => props.theme.transition.theme};

  padding-top: ${headerHeight};
  z-index: 100;
`;

export const ContentWrapper = styled.div`
  padding-top: ${(props) => props.theme.space.large_32};
  padding-bottom: ${(props) => props.theme.space.large_32};
  padding-left: ${(props) => props.theme.space.large_32};
  padding-right: 0.875rem; // container padding

  @media (min-width: ${(props) => props.theme.device.tablet}) {
    padding-right: 2.5rem; // container padding
  }

  height: calc(100vh - ${headerHeight});

  overflow: auto;

  &::-webkit-scrollbar {
    // Hide scrollbar for Chrome, Safari and Opera
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`;

export const DefaultMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.default_16};
  align-items: end;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.text.header};
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;

export const StyledRow = styled(Row)`
  padding-bottom: ${(props) => props.theme.space.default_16};
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 250%;
    height: 1px;
    background-color: ${(props) => adjustAlpha(props.theme.palette.divider.header, 0.3)};
  }

  @media (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;
