import { Options } from '@hey-api/client-fetch';

import {
  listAllowedUserAchievementStatus,
  type ListAllowedUserAchievementStatusResponse,
  UserAchievementAllowedStatusItemResponse,
} from '@Root/src/api-generated';

import { ApiError } from '@Api/ApiError';
import UserAchievementAllowedStatusItemModel from '@Api/models/UserAchievementAllowedStatusItemModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi } from '@Api/useApi';

const hydrateUserAchievementAllowed = (
  apiResponse: UserAchievementAllowedStatusItemResponse
): UserAchievementAllowedStatusItemModel => {
  return UserAchievementAllowedStatusItemModel.hydrateFromApi(apiResponse);
};

const hydrateUserAchievementsAllowed = (response: {
  data: UserAchievementAllowedStatusItemResponse[];
}): UserAchievementAllowedStatusItemModel[] => {
  return response.data.map((userAchievement) => hydrateUserAchievementAllowed(userAchievement));
};

export function useListUserAchievementAllowedStatus(
  onSuccess?: (result: UserAchievementAllowedStatusItemModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options, UserAchievementAllowedStatusItemModel[], ListAllowedUserAchievementStatusResponse>(
    listAllowedUserAchievementStatus,
    hydrateUserAchievementsAllowed,
    onSuccess,
    onError,
    onValidationError
  );
}
