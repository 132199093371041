import { UserActivityHeatmapValueResponse } from '@Api-generated';

export default class UserActivityHeatmapValueModel implements UserActivityHeatmapValueResponse {
  public createdAt: string;
  public count: number;

  constructor(createdAt: string, count: number) {
    this.createdAt = createdAt;
    this.count = count;
  }

  public static hydrateFromApi(
    userActivityHeatmapValueResponse: UserActivityHeatmapValueResponse
  ): UserActivityHeatmapValueModel {
    return new UserActivityHeatmapValueModel(
      userActivityHeatmapValueResponse.createdAt,
      userActivityHeatmapValueResponse.count
    );
  }
}
