import { NotificationSettingResponse } from '@Api-generated';

import { NotificationType } from './NotificationModel';
import UserModel from './UserModel';

export default class NotificationSettingModel implements NotificationSettingResponse {
  public id: string;
  public createdAt?: string;
  public updatedAt?: string;
  public isEnabled: boolean;
  public notificationType: NotificationType;
  public user?: UserModel;

  constructor(
    id: string,
    isEnabled: boolean,
    notificationType: NotificationType,
    createdAt?: string,
    updatedAt?: string,
    user?: UserModel
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isEnabled = isEnabled;
    this.notificationType = notificationType;
    this.user = user;
  }

  public static hydrateFromApi(notificationSettingResponse: NotificationSettingResponse) {
    const notificationType = notificationSettingResponse.notificationType as NotificationType;

    const userModel = notificationSettingResponse.user
      ? UserModel.hydrateFromApi(notificationSettingResponse.user)
      : undefined;

    return new NotificationSettingModel(
      notificationSettingResponse.id,
      notificationSettingResponse.isEnabled,
      notificationType,
      notificationSettingResponse.createdAt,
      notificationSettingResponse.updatedAt,
      userModel
    );
  }
}
