import styled from 'styled-components';

interface ThemePreviewColor {
  color: string;
  border: string;
}

interface BackgroundColor {
  start: string;
  end: string;
  border: string;
}

export const BackgroundColor = styled.div<BackgroundColor>`
  background: linear-gradient(180deg, ${(props) => props.start} 0%, ${(props) => props.end} 70%);

  height: 20px;
  width: 55px;
  border: 1px solid ${(props) => props.border};
  border-radius: 1em;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0.2rem;
`;

export const Color = styled.div<ThemePreviewColor>`
  background-color: ${(props) => props.color};

  height: 13px;
  width: 13px;
  border: 1px solid ${(props) => props.border};
  border-radius: 50%;
`;
