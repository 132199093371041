import type { Options } from '@hey-api/client-fetch';

import { ApiError } from '@Api/ApiError';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi } from '@Api/useApi';

import {
  getLanguage,
  GetLanguageData,
  LanguageResponse,
  listLanguages,
  type ListLanguagesData,
  ListLanguagesResponse,
} from '../../api-generated';
import LanguageModel from '../models/LanguageModel';

const hydrateLanguageModel = (apiResponse: LanguageResponse): LanguageModel => {
  return LanguageModel.hydrateFromApi(apiResponse);
};

const hydrateListLanguagesModels = (response: { data: LanguageResponse[] }): LanguageModel[] => {
  return response.data.map((language) => hydrateLanguageModel(language));
};

export function useGetLanguage(
  onSuccess?: (result: LanguageModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<GetLanguageData>, LanguageModel, LanguageResponse>(
    getLanguage,
    hydrateLanguageModel,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useListLanguages(
  onSuccess?: (result: LanguageModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<ListLanguagesData>, LanguageModel[], ListLanguagesResponse>(
    listLanguages,
    hydrateListLanguagesModels,
    onSuccess,
    onError,
    onValidationError
  );
}
