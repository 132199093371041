import styled from 'styled-components';

import Divider from '@Components/atoms/Divider';

export const Root = styled.footer`
  position: relative;
  background-color: ${(props) => props.theme.palette.footer.background};
  transition: background-color ${(props) => props.theme.transition.theme};
  padding-bottom: ${(props) => props.theme.space.default_16};
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.palette.footer.text};
  opacity: 0.2;
  margin-bottom: ${(props) => props.theme.space.large_32};
`;
