export class SettingsModel {
  private _themeId: string;

  private constructor(themeId: string) {
    this._themeId = themeId;
  }

  public static hydrateFromObject(data: { themeId: string }) {
    return new SettingsModel(data.themeId);
  }

  public static hydrateFromJson(data: string) {
    return SettingsModel.hydrateFromObject(JSON.parse(data));
  }

  public serialize(): string {
    return JSON.stringify({
      themeId: this._themeId,
    });
  }

  get themeId(): string {
    return this._themeId;
  }

  set themeId(theme: string) {
    this._themeId = theme;
  }
}
