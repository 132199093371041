import { TypingTestFontSize } from '@Components/organisms/TypingBox/TypingBox';

export class TypingBoxSettingsModel {
  private _fontFace: string;
  private _fontSize: TypingTestFontSize;
  private _rows: number;
  private _useHighContrast: boolean;
  private _useTimer: boolean;
  private _showStats: boolean;
  private _showInvisibleChars: boolean;

  private constructor(
    fontFace: string,
    fontSize: TypingTestFontSize,
    rows: number,
    useHighContrast: boolean,
    useTimer: boolean,
    showStats: boolean,
    showInvisibleChars: boolean
  ) {
    this._fontFace = fontFace;
    this._fontSize = fontSize;
    this._rows = rows;
    this._useHighContrast = useHighContrast;
    this._useTimer = useTimer;
    this._showStats = showStats;
    this._showInvisibleChars = showInvisibleChars;
  }

  public static hydrateFromObject(data: {
    fontFace: string;
    fontSize: TypingTestFontSize;
    rows: number;
    useHighContrast: boolean;
    useTimer: boolean;
    showStats: boolean;
    showAdvancedResult: boolean;
    showInvisibleChars: boolean;
  }) {
    const fontSize = data.fontSize;

    return new TypingBoxSettingsModel(
      data.fontFace,
      fontSize,
      data.rows,
      data.useHighContrast,
      data.useTimer,
      data.showStats,
      data.showInvisibleChars
    );
  }

  public static hydrateFromJson(data: string) {
    return TypingBoxSettingsModel.hydrateFromObject(JSON.parse(data));
  }

  public serialize(): string {
    return JSON.stringify({
      fontFace: this._fontFace,
      fontSize: this._fontSize,
      rows: this._rows,
      useHighContrast: this._useHighContrast,
      useTimer: this._useTimer,
      showStats: this._showStats,
      showInvisibleChars: this._showInvisibleChars,
    });
  }

  get fontFace(): string {
    return this._fontFace;
  }

  set fontFace(fontFace: string) {
    this._fontFace = fontFace;
  }

  get fontSize(): TypingTestFontSize {
    return this._fontSize;
  }

  set fontSize(fontSize: TypingTestFontSize) {
    this._fontSize = fontSize;
  }

  get rows(): number {
    return this._rows;
  }

  set rows(rows: number) {
    this._rows = rows;
  }

  get useHighContrast(): boolean {
    return this._useHighContrast;
  }

  set useHighContrast(useHighContrast: boolean) {
    this._useHighContrast = useHighContrast;
  }

  get useTimer(): boolean {
    return this._useTimer;
  }

  set useTimer(useTimer: boolean) {
    this._useTimer = useTimer;
  }

  get showStats(): boolean {
    return this._showStats;
  }

  set showStats(showStats: boolean) {
    this._showStats = showStats;
  }

  get showInvisibleChars(): boolean {
    return this._showInvisibleChars;
  }

  set showInvisibleChars(showInvisibleChars: boolean) {
    this._showInvisibleChars = showInvisibleChars;
  }
}
