import { ModFlaggedUserListResponse } from '@Root/src/api-generated';

import ModFlaggedUserListMetaModel from '@Api/models/ModFlaggedUserListMetaModel';
import UserModel from '@Api/models/UserModel';

export default class ModFlaggedUserListModel implements ModFlaggedUserListResponse {
  public data: UserModel[];
  public meta: ModFlaggedUserListMetaModel;

  constructor(data: UserModel[], meta: ModFlaggedUserListMetaModel) {
    this.data = data;
    this.meta = meta;
  }

  public static hydrateFromApi(modUserListResponse: ModFlaggedUserListResponse): ModFlaggedUserListModel {
    const data = modUserListResponse.data?.map((modUserResponse) => UserModel.hydrateFromApi(modUserResponse));

    const meta = ModFlaggedUserListMetaModel.hydrateFromApi(modUserListResponse.meta);

    return new ModFlaggedUserListModel(data, meta);
  }
}
