import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationModel from '@Api/models/NotificationModel';

import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import { RowGap } from '@Components/atoms/Row/Row';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import LanguageSwitcher from '@Components/molecules/LanguageSwitcher/LanguageSwitcher';
import NotificationCenter from '@Components/molecules/NotificationCenter/NotificationCenter';
import ThemeSwitcher from '@Components/molecules/ThemeSwitcher/ThemeSwitcher';
import UserDropDown from '@Components/molecules/UserDropDown/UserDropDown';
import { Content } from '@Components/organisms/Header/Header';
import { headerHeight } from '@Components/organisms/Header/Header.styles';
import { Routes } from '@Components/Routes';

import { AntiCheatGameResult } from '@Helpers/result';

import { ContentWrapper, DefaultMenu, Root, StyledLink, StyledRow } from './Sidebar.styles';

interface Props extends PropsWithClassName {
  content: NonNullable<Content>;
  isUnmounting: boolean;
  handleCloseSidebar: () => void;
  notifications: NotificationModel[];
  refreshNotifications: () => void;
  handleAntiCheatResultUpdate: (result: AntiCheatGameResult) => void;
}

const Sidebar = (props: Props): React.ReactElement => {
  const {
    className,
    dataTestId,
    content,
    handleCloseSidebar,
    notifications,
    refreshNotifications,
    isUnmounting,
    handleAntiCheatResultUpdate,
  } = props;

  const { t } = useTranslation('global');
  const { loggedIn } = useGlobalContext();

  const sidebarRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState(0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!sidebarRef.current || sidebarRef.current.contains(event.target as Node)) {
        return;
      }

      // ignore click in header
      if (event.pageY < parseInt(headerHeight)) {
        return;
      }

      handleCloseSidebar();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCloseSidebar, sidebarRef]);

  // width transition between contents
  useLayoutEffect(() => {
    if (!sidebarRef.current || content === null) {
      return;
    }

    if (width === 0) {
      setWidth(sidebarRef.current.scrollWidth);
      return;
    }

    const startWidth = width;
    const endWidth = sidebarRef.current.scrollWidth;

    if (startWidth === endWidth) {
      return;
    }

    setWidth(endWidth);

    requestAnimationFrame(() => {
      if (!sidebarRef.current) {
        return;
      }

      sidebarRef.current.style.width = startWidth + 'px';
      sidebarRef.current.style.transition = '200ms all ease-out';

      setTimeout(() => {
        if (!sidebarRef.current) {
          return;
        }

        sidebarRef.current.style.width = 'auto';
        sidebarRef.current.style.transition = 'none';
      }, 200);

      requestAnimationFrame(() => {
        if (!sidebarRef.current) {
          return;
        }

        sidebarRef.current.style.width = endWidth + 'px';
      });
    });
  }, [content, width]);

  const renderUserLoginOrUserArea = () => {
    if (loggedIn) {
      return <></>;
    }

    return (
      <StyledRow noGutters rowGap={RowGap.Default}>
        <StyledLink
          href={Routes.Login}
          onClick={handleCloseSidebar}
          size={LinkSize.Medium}
          weight={LinkWeight.Light}
          hideUnderline
          dataTestId="sidebar-button-login"
        >
          {t('navigation.header.login')}
        </StyledLink>
        <StyledLink
          href={Routes.SignUp}
          onClick={handleCloseSidebar}
          size={LinkSize.Medium}
          weight={LinkWeight.Light}
          hideUnderline
          dataTestId="sidebar-button-signup"
        >
          {t('navigation.header.signup')}
        </StyledLink>
      </StyledRow>
    );
  };

  const renderDefaultContent = () => (
    <DefaultMenu>
      {renderUserLoginOrUserArea()}
      <StyledLink
        href={Routes.TypingTest}
        onClick={handleCloseSidebar}
        size={LinkSize.Medium}
        weight={LinkWeight.Light}
        hideUnderline
        dataTestId={'sidebar-link-typing-test-root'}
      >
        {t('mode.typing_test')}
      </StyledLink>
      <StyledLink
        href={Routes.Competitions}
        onClick={handleCloseSidebar}
        size={LinkSize.Medium}
        weight={LinkWeight.Light}
        hideUnderline
        dataTestId={'sidebar-link-competition-root'}
      >
        {t('mode.competition')}
      </StyledLink>
      <StyledLink
        href={Routes.Multiplayer}
        onClick={handleCloseSidebar}
        size={LinkSize.Medium}
        weight={LinkWeight.Light}
        hideUnderline
        dataTestId={'sidebar-link-multiplayer-root'}
      >
        {t('mode.multiplayer')}
      </StyledLink>
      <StyledLink
        href={Routes.TextPractice}
        onClick={handleCloseSidebar}
        size={LinkSize.Medium}
        weight={LinkWeight.Light}
        hideUnderline
        dataTestId={'sidebar-link-text-practice-root'}
      >
        {t('mode.text_practice')}
      </StyledLink>
      <StyledLink
        href={Routes.CustomTypingTest}
        onClick={handleCloseSidebar}
        size={LinkSize.Medium}
        weight={LinkWeight.Light}
        hideUnderline
        dataTestId={'sidebar-link-custom-typing-test-root'}
      >
        {t('mode.custom_typing_test')}
      </StyledLink>
    </DefaultMenu>
  );

  const renderContent = () => {
    switch (content) {
      case 'languages':
        return <LanguageSwitcher />;
      case 'themes':
        return <ThemeSwitcher />;
      case 'user':
        return <UserDropDown handleCloseSidebar={handleCloseSidebar} />;
      case 'notifications':
        return (
          <NotificationCenter
            handleCloseSidebar={handleCloseSidebar}
            notifications={notifications}
            refreshNotifications={refreshNotifications}
            handleAntiCheatResultUpdate={handleAntiCheatResultUpdate}
          />
        );
      case 'default':
      default:
        return renderDefaultContent();
    }
  };

  return (
    <Root
      className={className}
      ref={sidebarRef}
      data-testid={dataTestId ?? 'sidebar-root'}
      $isUnmounting={isUnmounting}
    >
      <ContentWrapper data-testid="sidebar-content">{renderContent()}</ContentWrapper>
    </Root>
  );
};

export default Sidebar;
