'use client';

import { useEffect, useRef, useState } from 'react';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import NotificationModel from '@Api/models/NotificationModel';
import { useListNotifications } from '@Api/services/NotificationService';

import { errorToast } from '@Components/helper/toastHelper';

const pollingInterval = 10000;

export const useNotificationPolling = (
  user: LocalStorageUserModel | null,
  type: 'app' | 'toast',
  isPaused?: boolean,
  handleNotifications?: (result: NotificationModel[]) => void
): [NotificationModel[], () => void] => {
  // double save notifications to mutable ref to keep changes in onSuccess callback
  const notificationsRef = useRef<NotificationModel[]>([]);
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);

  const { executeRequest: listNotificationsExecuteRequest } = useListNotifications(
    (result) => {
      if (
        result.map((notification) => JSON.stringify(notification)).join(',') ===
        notificationsRef.current.map((notification) => JSON.stringify(notification)).join(',')
      ) {
        // prevent rerendering if nothing has changed
        return;
      }

      if (handleNotifications) {
        handleNotifications(result);
      }

      notificationsRef.current = result;
      setNotifications(result);
    },
    (error) => {
      errorToast(error);
    }
  );

  useEffect(() => {
    refreshNotifications();

    const interval = setInterval(async () => {
      if (isPaused) {
        return;
      }

      await refreshNotifications();
    }, pollingInterval);

    return () => clearInterval(interval);
  }, [user, isPaused]);

  const refreshNotifications = async () => {
    if (user === null) {
      if (notifications.length > 0) {
        setNotifications([]);
      }
      return;
    }

    await listNotificationsExecuteRequest({ path: { type: type } });
  };

  return [notifications, refreshNotifications];
};
