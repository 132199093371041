'use client';

import Script from 'next/script';
import React from 'react';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';

import { PropsWithClassName } from '@Components/helper';
import Footer from '@Components/organisms/Footer/Footer';
import Header from '@Components/organisms/Header/Header';

import { Root } from './DefaultTemplate.styles';

interface Props extends PropsWithClassName {
  user?: string;
  authorization?: string;
  children?: React.ReactNode;
}

const DefaultTemplate = (props: Props): React.ReactElement => {
  const { user, children, className, dataTestId, authorization } = props;

  const userModel = user && authorization ? LocalStorageUserModel.hydrateFromJson(user, authorization) : undefined;

  return (
    <Root className={className} data-testid={dataTestId ?? 'DefaultTemplate-root'}>
      {process.env.NEXT_PUBLIC_APP_ENV !== 'dev' && (
        <Script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="db664c60-28a0-4b96-95e3-614fd6d90680"
          data-blockingmode="auto"
          data-framework="TCFv2.2"
          strategy="afterInteractive"
        />
      )}
      <Header serverUser={userModel} />
      {children}
      <Footer />
    </Root>
  );
};

export default DefaultTemplate;
