import { AchievementType } from '@Api/models/UserAchievementModel';

import { UserAchievementAllowedStatusItemResponse } from '@Api-generated';

export default class UserAchievementAllowedStatusItemModel implements UserAchievementAllowedStatusItemResponse {
  public isAchieved: boolean;
  public achievementType: AchievementType;

  constructor(isAchieved: boolean, achievementType: AchievementType) {
    this.isAchieved = isAchieved;
    this.achievementType = achievementType;
  }

  public static hydrateFromApi(
    response: UserAchievementAllowedStatusItemResponse
  ): UserAchievementAllowedStatusItemModel {
    const achievementType = response.achievementType as AchievementType;

    return new UserAchievementAllowedStatusItemModel(response.isAchieved, achievementType);
  }
}
