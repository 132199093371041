import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { StyledContainer } from './ToastContainer.styles';

interface Props extends PropsWithClassName {}

const ToastContainer = (props: Props): React.ReactElement => {
  return (
    <div data-testid={props.dataTestId ?? 'toast-container-root'}>
      <StyledContainer stacked />
    </div>
  );
};

export default ToastContainer;
