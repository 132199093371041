import styled from 'styled-components';

export const Root = styled.div<{ $isDeleted: boolean }>`
  cursor: pointer;
  overflow: hidden;

  border-bottom: ${(props) => (props.$isDeleted ? 'none' : `1px solid ${props.theme.palette.divider.header}`)};
`;

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${(props) => props.theme.device.desktop}) {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
`;
