import styled from 'styled-components';

import Row from '@Components/atoms/Row';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${(props) => props.theme.space.small_8};
`;

export const StyledRow = styled(Row)`
  cursor: pointer;

  &:hover {
    p {
      color: ${(props) => props.theme.palette.text.themePrimary};
    }
  }
`;
