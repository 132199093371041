import styled from 'styled-components';

import Text from '@Components/atoms/Text';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.large_32};
`;

export const StyledText = styled(Text)`
  width: 100%;
  text-align: end;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledRow = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: ${(props) => props.theme.space.small_8};
  cursor: pointer;
  width: 100%;

  &:hover {
    p {
      color: ${(props) => props.theme.palette.text.themePrimary};
    }
  }
`;

export const ThemeGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${(props) => props.theme.space.default_16};
`;
