import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { TextSize } from '@Helpers/types/text';

import { StyledText } from './Copyright.styles';

interface Props extends PropsWithClassName {}

const Copyright = (props: Props): React.ReactElement => {
  const year = new Date().getFullYear().toString();
  const copyText = `© ${year} 10FastFingers, Inc. All rights reserved.`;
  return (
    <div className={props.className} data-testid={props.dataTestId ?? 'copyright-root'}>
      <StyledText size={TextSize.Xs}>{copyText}</StyledText>
    </div>
  );
};

export default Copyright;
