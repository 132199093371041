import { UserActivityRadarResponse } from '@Api-generated';

export default class UserActivityRadarModel implements UserActivityRadarResponse {
  public typingTestResults: number;
  public competitionResults: number;
  public textPracticeResults: number;
  public casualMatchResults: number;

  constructor(
    typingTestResults: number,
    competitionResults: number,
    textPracticeResults: number,
    casualMatchResults: number
  ) {
    this.typingTestResults = typingTestResults;
    this.competitionResults = competitionResults;
    this.textPracticeResults = textPracticeResults;
    this.casualMatchResults = casualMatchResults;
  }

  public static hydrateFromApi(userActivityRadarResponse: UserActivityRadarResponse): UserActivityRadarModel {
    return new UserActivityRadarModel(
      userActivityRadarResponse.typingTestResults,
      userActivityRadarResponse.competitionResults,
      userActivityRadarResponse.textPracticeResults,
      userActivityRadarResponse.casualMatchResults
    );
  }
}
