// import { cookies } from 'next/headers';

interface CookieOptions {
  maxAge?: number;
  httpOnly?: boolean;
  path?: string;
  sameSite?: 'lax' | 'strict' | 'none';
  secure?: boolean;
}
export enum CookieKey {
  Authorization = 'Authorization',
  RefreshToken = 'RefreshToken',
  Translation = 'Translation',
  User = 'User',
}

// does only work in SSR
// export function getCookieValue(key: string) {
//   const cookieStore = cookies();
//   return cookieStore.get(key)?.value;
// }

export const getCookieValue = (name: string, value: string, options: CookieOptions = {}) => {
  let cookieValue = `${name}=${value}`;

  if (options.httpOnly) {
    cookieValue += '; HttpOnly';
  }

  if (options.secure) {
    cookieValue += '; Secure';
  }

  if (options.sameSite) {
    switch (options.sameSite) {
      case 'lax':
        cookieValue += '; SameSite=Lax';
        break;
      case 'strict':
        cookieValue += '; SameSite=Strict';
        break;
      case 'none':
        cookieValue += '; SameSite=None';
        break;
    }
  }

  if (options.path) {
    cookieValue += '; Path=' + options.path;
  }

  if (options.maxAge) {
    cookieValue += '; Max-Age=' + options.maxAge;
  }

  return cookieValue;
};
