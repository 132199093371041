import styled from 'styled-components';

import Text from '@Components/atoms/Text';

export const DeleteAllButton = styled(Text)`
  margin: ${(props) => props.theme.space.small_8};
  color: ${(props) => props.theme.palette.text.error};
  cursor: pointer;
`;

export const EmptyNotifications = styled(Text)`
  margin-top: 3.75rem;
  text-align: center;
`;
