import React from 'react';

import Col from '@Components/atoms/Col';
import Container from '@Components/atoms/Container';
import Copyright from '@Components/atoms/Copyright/Copyright';
import FooterMessage from '@Components/atoms/FooterMessage/FooterMessage';
import Row from '@Components/atoms/Row';
import SocialIcons from '@Components/atoms/SocialIcons/SocialIcons';
import { PropsWithClassName } from '@Components/helper';
import NavigationFooter from '@Components/molecules/NavigationFooter/NavigationFooter';

import { Root, StyledDivider } from './Footer.styles';

interface Props extends PropsWithClassName {}

const Footer = (props: Props): React.ReactElement => {
  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'footer-root'}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={3} lg={3}>
            <FooterMessage />
            <SocialIcons />
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <NavigationFooter />
          </Col>
        </Row>
        <StyledDivider />
        <Copyright />
      </Container>
    </Root>
  );
};

export default Footer;
