import styled from 'styled-components';

import Text from '@Components/atoms/Text';

export const StyledText = styled(Text)`
  font-weight: ${(props) => props.theme.font.text.light.medium.fontWeight};
  opacity: 0.7;
  color: ${(props) => props.theme.palette.footer.textLight};
  margin-bottom: ${(props) => props.theme.space.default_16};
`;
