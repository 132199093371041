import React from 'react';
import { DefaultTheme } from 'styled-components';

import ThemeModel from '@Api/models/ThemeModel';

import { PropsWithClassName } from '@Components/helper';

import { BackgroundColor, Color } from './ThemePreview.styles';

interface Props extends PropsWithClassName {
  theme: DefaultTheme;
  baseColors: ThemeModel;
}

const ThemePreview = (props: Props): React.ReactElement => {
  const { theme, baseColors } = props;

  if (baseColors.colors === undefined) {
    return <></>;
  }

  return (
    <BackgroundColor
      start={baseColors.colors.backgroundGradientStart}
      end={baseColors.colors.backgroundGradientEnd}
      border={baseColors.colors.border}
      className={props.className}
      data-testid={'ThemePreview-root'}
    >
      <Color color={baseColors.colors.primary} border={theme.palette.input.border.hover} />
      <Color color={baseColors.colors.backgroundBox} border={theme.palette.input.border.hover} />
      <Color color={baseColors.colors.primaryText} border={theme.palette.input.border.hover} />
    </BackgroundColor>
  );
};

export { ThemePreview };
