import { IconX } from '@tabler/icons-react';
import styled from 'styled-components';

import Divider from '@Components/atoms/Divider';
import Headline from '@Components/atoms/Headline';

export const Root = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.box};
  position: relative;
  margin-bottom: ${(props) => props.theme.space.xl_64};
  padding: ${(props) => props.theme.space.default_16};

  background-color: ${(props) => props.theme.palette.box.background.primary};
  border: 1px solid ${(props) => props.theme.palette.box.border.default};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${(props) => props.theme.space.default_16};
`;
export const StyledHeadline = styled(Headline)`
  width: 100%;
  margin: 0;
`;

export const Close = styled.div`
  position: absolute;
  top: ${(props) => props.theme.space.default_16};
  right: ${(props) => props.theme.space.default_16};
  cursor: pointer;
`;

export const StyledIconX = styled(IconX)`
  stroke: ${(props) => props.theme.palette.text.primary};
  transition: stroke ${(props) => props.theme.transition.theme};
`;
