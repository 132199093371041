import styled from 'styled-components';

import { LinkColor, LinkSize, LinkWeight } from '@Components/atoms/Link/Link';

import Link from '@Helpers/i18n/Link';
import { TextTransform } from '@Helpers/types/text';

interface LinkProps {
  $weight: LinkWeight;
  $size: LinkSize;
  $color: LinkColor;
  $hideUnderline: boolean;
  $transform: TextTransform;
}

export const Root = styled(Link)<LinkProps>`
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text[props.$weight][props.$size].fontSize};
  font-weight: ${(props) => props.theme.font.text[props.$weight][props.$size].fontWeight};
  line-height: ${(props) => props.theme.font.text[props.$weight][props.$size].lineHeight};
  letter-spacing: ${(props) => props.theme.font.text[props.$weight][props.$size].letterSpacing};
  color: ${(props) => props.theme.palette.text[props.$color]};
  transition: color ${(props) => props.theme.transition.theme};

  text-transform: ${(props) => props.$transform};

  b,
  strong {
    font-weight: bold;
  }

  &:hover {
    text-decoration: ${(props) =>
      props.$hideUnderline ? 'none' : 'underline ' + props.theme.palette.text[props.$color]};
  }
`;
