import { LoginResponse } from '@Api-generated';

export default class LoginModel implements LoginResponse {
  public code: number;
  public data: {
    token: string;
    refreshToken: string;
    refreshTokenExpiration: number;
  };
  public message: string;
  public readonly isSuccessful: boolean;

  constructor(
    code: number,
    data: {
      token: string;
      refreshToken: string;
      refreshTokenExpiration: number;
    },
    message: string
  ) {
    this.code = code;
    this.data = data;
    this.message = message;
    this.isSuccessful = code === 200;
  }

  public static hydrateFromApi(loginResponse: LoginResponse) {
    return new LoginModel(loginResponse.code, loginResponse.data, loginResponse.message);
  }
}
