import { Options } from '@hey-api/client-fetch';

import {
  DefaultResponse,
  deleteNotifications,
  type DeleteNotificationsData,
  listNotifications,
  type ListNotificationsData,
  type ListNotificationsResponse,
  listUserNonCompliantNotifications,
  type ListUserNonCompliantNotificationsData,
  type ListUserNonCompliantNotificationsResponse,
  NotificationResponse,
  readNotifications,
  type ReadNotificationsData,
} from '@Root/src/api-generated';

import { ApiError } from '@Api/ApiError';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import NotificationModel from '@Api/models/NotificationModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi, useApiWithoutStates } from '@Api/useApi';

const hydrateDefaultResponse = (apiResponse: DefaultResponse): DefaultResponseModel => {
  return DefaultResponseModel.hydrateFromApi(apiResponse);
};

const hydrateNotification = (apiResponse: NotificationResponse): NotificationModel => {
  return NotificationModel.hydrateFromApi(apiResponse);
};

const hydrateNotifications = (response: { data: NotificationResponse[] }): NotificationModel[] => {
  return response.data.map((userAchievement) => hydrateNotification(userAchievement));
};

export function useListNotifications(
  onSuccess?: (result: NotificationModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApiWithoutStates<Options<ListNotificationsData>, NotificationModel[], ListNotificationsResponse>(
    listNotifications,
    hydrateNotifications,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useListUserNonCompliantNotifications(
  onSuccess?: (result: NotificationModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<
    Options<ListUserNonCompliantNotificationsData>,
    NotificationModel[],
    ListUserNonCompliantNotificationsResponse
  >(listUserNonCompliantNotifications, hydrateNotifications, onSuccess, onError, onValidationError);
}

export function useReadNotifications(
  onSuccess?: (result: DefaultResponse) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<ReadNotificationsData>, DefaultResponseModel, DefaultResponse>(
    readNotifications,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useDeleteNotifications(
  onSuccess?: (result: DefaultResponse) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<DeleteNotificationsData>, DefaultResponseModel, DefaultResponse>(
    deleteNotifications,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}
