import React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';
import { Routes } from '@Components/Routes';

import { TextSize } from '@Helpers/types/text';

import { Root, StyledCol, StyledLink, StyledText } from './NavigationFooter.styles';

interface Props extends PropsWithClassName {}

const NavigationFooter = (props: Props): React.ReactElement => {
  const { t } = useTranslation('global');

  const sections: {
    label: string;
    testId: string;
    options: { label: string; href: string; testId: string }[];
  }[] = [
    {
      label: t('navigation.footer.games'),
      testId: 'navigation-footer-games',
      options: [
        {
          label: t('mode.typing_test'),
          href: Routes.TypingTest,
          testId: 'navigation-footer-typing-test',
        },
        {
          label: t('mode.competition'),
          href: Routes.Competitions,
          testId: 'navigation-footer-competition',
        },
        {
          label: t('mode.text_practice'),
          href: Routes.TextPractice,
          testId: 'navigation-footer-text-practice',
        },
        {
          label: t('mode.multiplayer'),
          href: Routes.Multiplayer,
          testId: 'navigation-footer-multiplayer',
        },
        {
          label: t('mode.custom_typing_test'),
          href: Routes.CustomTypingTest,
          testId: 'customTypingTest',
        },
      ],
    },
    {
      label: t('navigation.footer.support'),
      testId: 'navigation-footer-support',
      options: [
        {
          label: t('navigation.footer.about'),
          href: Routes.About,
          testId: 'navigation-footer-about',
        },
        {
          label: t('navigation.footer.blog'),
          href: Routes.Blog,
          testId: 'navigation-footer-blog',
        },
        {
          label: t('navigation.footer.faq'),
          href: Routes.Faq,
          testId: 'navigation-footer-faq',
        },
        {
          label: t('navigation.footer.feedback'),
          href: 'https://feedback.10fastfingers.com',
          testId: 'navigation-footer-feedback',
        },
        {
          label: t('navigation.footer.translate'),
          href: Routes.Faq + '#translate',
          testId: 'navigation-footer-translate',
        },
      ],
    },
    {
      label: t('navigation.footer.legal'),
      testId: 'navigation-footer-legal',
      options: [
        {
          label: t('navigation.footer.imprint'),
          href: Routes.Imprint,
          testId: 'navigation-footer-imprint',
        },
        {
          label: t('navigation.footer.privacy'),
          href: Routes.Privacy,
          testId: 'navigation-footer-privacy',
        },
        // {
        //   label: t('navigation.footer.terms'),
        //   href: Routes.Terms,
        //   testId: 'navigation-footer-terms',
        // },
        {
          label: t('navigation.footer.cookie_policy'),
          href: `${Routes.Privacy}#cookies`,
          testId: 'navigation-footer-cookie-policy',
        },
      ],
    },
  ];

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'navigation-footer-root'}>
      <Row columnGap={RowGap.Large}>
        {sections.map((section) => (
          <StyledCol key={section.label} xs={6} sm={3} md={2} lg={2}>
            <StyledText size={TextSize.Small} dataTestId={section.testId}>
              {section.label}
            </StyledText>
            {/* <SectionHeadline importance={5} >
              {section.label}
            </SectionHeadline> */}
            {section.options.map((option, index) => {
              if (option.href[0] === '/') {
                return (
                  <StyledLink
                    key={option.label + index}
                    href={option.href}
                    dataTestId={option.testId}
                    weight={LinkWeight.Light}
                    size={LinkSize.Small}
                    hideUnderline
                  >
                    {option.label}
                  </StyledLink>
                );
              }
              return (
                <StyledLink
                  key={option.label + index}
                  href={option.href}
                  weight={LinkWeight.Light}
                  size={LinkSize.Small}
                  target="_blank"
                  rel="noreferrer"
                  dataTestId={option.testId}
                  hideUnderline
                >
                  {option.label}
                </StyledLink>
              );
            })}
          </StyledCol>
        ))}
      </Row>
    </Root>
  );
};

export default NavigationFooter;
