'use client';

import { Role } from '@Root/src/api-generated';

import UserModel, { AccountType } from '@Api/models/UserModel';

import { UserImageSrcModel } from '@Components/atoms/UserImage/UserImage';
import { jwtDecode } from '@Components/helper/jwt';

interface UserData {
  id: string;
  username: string;
  gravatar: UserImageSrcModel;
  accountType: AccountType;
}

interface AuthData {
  roles: Role[];
}

// todo: refactor to CookieUserModal because it is not saved in LocalStorage anymore?
export class LocalStorageUserModel {
  readonly id: string;
  readonly username: string;
  readonly gravatar: UserImageSrcModel;
  readonly accountType: AccountType;
  readonly roles: Role[];

  private constructor(
    id: string,
    username: string,
    gravatar: UserImageSrcModel,
    accountType: AccountType,
    roles: Role[]
  ) {
    this.id = id;
    this.username = username;
    this.gravatar = gravatar;
    this.accountType = accountType;
    this.roles = roles;
  }

  public static hydrateFromObject(userData: UserModel | UserData, authData: AuthData) {
    if (!userData.accountType) {
      // should never happen
      throw Error('Unexpected Error: could not create localStorageUser. Account type is not defined.');
    }

    return new LocalStorageUserModel(
      userData.id,
      userData.username,
      userData.gravatar,
      userData.accountType,
      authData.roles
    );
  }

  public static hydrateFromJson(userData: string, authData: string) {
    const parsedUserData: UserData = JSON.parse(userData);
    const parsedAuthData: AuthData = jwtDecode(authData);

    return LocalStorageUserModel.hydrateFromObject(parsedUserData, parsedAuthData);
  }

  public serialize(): string {
    return JSON.stringify({
      id: this.id,
      username: this.username,
      gravatar: this.gravatar,
      accountType: this.accountType,
    });
  }

  public isAdmin(): boolean {
    return this.roles.includes(Role.ROLE_ADMIN);
  }

  public isModerator(): boolean {
    return this.roles.includes(Role.ROLE_MODERATOR);
  }
}
