import ValidationErrorModel from '@Api/models/ValidationErrorModel';

import { ValidationErrorResponse } from '@Api-generated';

import { ApiError } from './ApiError';

export class ApiValidationError extends ApiError {
  public readonly response: ValidationErrorModel;

  constructor(
    request: Request,
    response: Response,
    error: {
      message: string;
      data: unknown;
      code: number;
    }
  ) {
    super(request, response, error.message);

    const apiResponse: ValidationErrorResponse = {
      code: error?.code,
      message: error?.message,
      data: error?.data as ValidationErrorResponse['data'],
    };
    this.response = ValidationErrorModel.hydrateFromApi(apiResponse);
  }
}
