import { IconBell, IconLanguage, IconPalette, TablerIconsProps } from '@tabler/icons-react';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';

import Container from '@Components/atoms/Container';
import { Icon } from '@Components/atoms/Icon/Icon';
import { LinkColor, LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import MenuButton from '@Components/atoms/MenuButton/MenuButton';
import { RowGap } from '@Components/atoms/Row/Row';
import { UserImageSize } from '@Components/atoms/UserImage/UserImage';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { useNotificationPolling } from '@Components/helper/useNotificationPolling';
import { LogoVariant } from '@Components/molecules/Logo/Logo';
import Sidebar from '@Components/molecules/Sidebar/Sidebar';
import AntiCheatModal from '@Components/organisms/AntiCheatModal/AntiCheatModal';
import { Routes } from '@Components/Routes';

import { AntiCheatGameResult } from '@Helpers/result';
import { TextColor, TextSize, TextTransform } from '@Helpers/types/text';

import { iconStroke } from '@Assets/styles/theme/theme';

import {
  Content,
  Counter,
  GameModeLink,
  HeaderRow,
  LoginSignupLink,
  MobileLogo,
  NotificationIcon,
  Root,
  StyledIconCompetition,
  StyledIconMultiplayer,
  StyledIconTypingtest,
  StyledRow,
  StyledUserImage,
  TabletDesktopLogo,
  Username,
  UserRow,
} from './Header.styles';

export type Content = 'default' | 'languages' | 'themes' | 'user' | 'notifications' | null;

interface Props extends PropsWithClassName {
  serverUser?: LocalStorageUserModel;
}

const Header = (props: Props): React.ReactElement => {
  const { serverUser } = props;

  const { t } = useTranslation('global');
  const { user: contextUser, isGlobalContextInitialized } = useGlobalContext();

  const user = useMemo(
    () => (isGlobalContextInitialized ? contextUser : serverUser),
    [contextUser, serverUser, isGlobalContextInitialized]
  );

  const [content, setContent] = useState<Content>(null);
  const [notifications, refreshNotifications] = useNotificationPolling(contextUser, 'app', content === 'notifications');
  const [isSidebarUnmounting, setSidebarIsUnmounting] = useState(false);
  const [result, setResult] = useState<AntiCheatGameResult | null>(null);

  const closeSidebar = useCallback(() => {
    if (content === null) {
      return;
    }

    setSidebarIsUnmounting(true);
    setTimeout(() => {
      setContent(null);
      setSidebarIsUnmounting(false);
    }, 500);
  }, [content]);

  useLayoutEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeSidebar();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeSidebar]);

  const handleIconClick = (clickedContent: Content) => {
    if (isSidebarUnmounting) {
      return;
    }

    if (content === clickedContent) {
      closeSidebar();
      return;
    }

    setContent(clickedContent);
  };

  const renderIcon = (icon: (props: TablerIconsProps) => JSX.Element, currentContent: Content) => (
    <Icon
      icon={icon}
      stroke={iconStroke}
      onClick={() => handleIconClick(currentContent)}
      color={content === currentContent && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header}
      dataTestId={`${currentContent}-trigger-root`}
      hoverColor={TextColor.ThemePrimary}
    />
  );

  const renderUserLoginOrUserArea = () => {
    if (user) {
      let counter = 0;
      if (notifications && notifications.length > 0) {
        const unreadNotifications = notifications.filter((notification) => notification.readAt === null);

        counter = unreadNotifications.length;
      }

      return (
        <>
          <NotificationIcon onClick={() => handleIconClick('notifications')}>
            {counter > 0 && <Counter>{counter}</Counter>}
            {renderIcon(IconBell, 'notifications')}
          </NotificationIcon>
          <div onClick={() => handleIconClick('user')}>
            <UserRow noGutters rowGap={RowGap.Small} xsMiddle dataTestId="user-trigger-root">
              <StyledUserImage
                $isOpen={content === 'user' && !isSidebarUnmounting}
                width={34}
                height={34}
                userImageSize={UserImageSize.Small}
                userImageSrcModel={user.gravatar}
              />
              <Username
                size={TextSize.Medium}
                color={content === 'user' && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header}
                dataTestId="user-trigger-username"
              >
                {user.username}
              </Username>
            </UserRow>
          </div>
        </>
      );
    }

    return (
      <StyledRow noGutters rowGap={RowGap.Default}>
        <LoginSignupLink
          href={Routes.Login}
          size={LinkSize.Medium}
          weight={LinkWeight.Light}
          onClick={closeSidebar}
          hideUnderline
          dataTestId="header-button-login"
        >
          {t('navigation.header.login')}
        </LoginSignupLink>
        <LoginSignupLink
          href={Routes.SignUp}
          size={LinkSize.Medium}
          weight={LinkWeight.Light}
          onClick={closeSidebar}
          hideUnderline
          dataTestId="header-button-signup"
        >
          {t('navigation.header.signup')}
        </LoginSignupLink>
      </StyledRow>
    );
  };

  return (
    <>
      <Root className={props.className} data-testid={'Header-root'}>
        <Container>
          <HeaderRow xsBetween noGutters xsMiddle>
            <TabletDesktopLogo height={32} onClick={closeSidebar} dataTestId="header-logo" isHeader />
            <MobileLogo
              height={32}
              onClick={closeSidebar}
              variant={LogoVariant.Logo}
              dataTestId="header-logo-mobile"
              isHeader
            />
            <Content rowGap={RowGap.Large} noGutters xsMiddle>
              <GameModeLink
                href={Routes.TypingTest}
                weight={LinkWeight.Light}
                size={LinkSize.Medium}
                color={LinkColor.Header}
                dataTestId={'navigation-main-typingtest'}
                transform={TextTransform.Capitalize}
                onClick={closeSidebar}
                hideUnderline
              >
                <StyledIconTypingtest size={24} isHeader />
                {t('mode.typing_test')}
              </GameModeLink>
              <GameModeLink
                href={Routes.Competitions}
                weight={LinkWeight.Light}
                size={LinkSize.Medium}
                color={LinkColor.Header}
                dataTestId={'navigation-main-competition'}
                transform={TextTransform.Capitalize}
                onClick={closeSidebar}
                hideUnderline
              >
                <StyledIconCompetition size={24} isHeader />
                {t('mode.competition')}
              </GameModeLink>
              <GameModeLink
                href={Routes.Multiplayer}
                weight={LinkWeight.Light}
                size={LinkSize.Medium}
                color={LinkColor.Header}
                transform={TextTransform.Capitalize}
                onClick={closeSidebar}
                dataTestId={'navigation-main-multiplayer'}
                hideUnderline
              >
                <StyledIconMultiplayer size={24} isHeader />
                {t('mode.multiplayer')}
              </GameModeLink>
              {renderIcon(IconPalette, 'themes')}
              {renderIcon(IconLanguage, 'languages')}
              {renderUserLoginOrUserArea()}
              <MenuButton
                isOpen={content === 'default' && !isSidebarUnmounting}
                onClick={() => handleIconClick('default')}
              />
            </Content>
          </HeaderRow>
        </Container>
      </Root>
      {content && (
        <Sidebar
          content={content}
          isUnmounting={isSidebarUnmounting}
          handleCloseSidebar={closeSidebar}
          notifications={notifications}
          refreshNotifications={refreshNotifications}
          handleAntiCheatResultUpdate={setResult}
        />
      )}
      {result && <AntiCheatModal result={result} onClose={() => setResult(null)} onSuccess={() => setResult(null)} />}
    </>
  );
};

export default Header;
