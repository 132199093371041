import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './Divider.styles';

interface Props extends PropsWithClassName {
  gradient?: boolean;
}

const Divider = (props: Props): React.ReactElement => {
  return <Root $gradient={props.gradient} className={props.className} data-testid={'Divider-root'} />;
};

export { Divider };
