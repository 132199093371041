import { Options } from '@hey-api/client-fetch';

import {
  createTheme,
  type CreateThemeData,
  DefaultResponse,
  deleteTheme,
  DeleteThemeData,
  listThemes,
  publishTheme,
  PublishThemeData,
  ThemeResponse,
  unpublishTheme,
  UnpublishThemeData,
  updateTheme,
  UpdateThemeData,
} from '@Root/src/api-generated';

import { ApiError } from '@Api/ApiError';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import ThemeModel from '@Api/models/ThemeModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi } from '@Api/useApi';

const hydrateThemes = (response: { data: ThemeResponse[] }): ThemeModel[] => {
  return response.data.map((theme) => hydrateTheme(theme));
};

const hydrateTheme = (apiResponse: ThemeResponse): ThemeModel => {
  return ThemeModel.hydrateFromApi(apiResponse);
};

const hydrateDefaultResponse = (apiResponse: DefaultResponse): DefaultResponseModel => {
  return DefaultResponseModel.hydrateFromApi(apiResponse);
};

export function useCreateTheme(
  onSuccess?: (result: ThemeModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<CreateThemeData>, ThemeModel, ThemeResponse>(
    createTheme,
    hydrateTheme,
    onSuccess,
    onError,
    onValidationError
  );
}

//todo: usePutApi ?
export function useUpdateTheme(
  onSuccess?: (result: ThemeModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UpdateThemeData>, ThemeModel, ThemeResponse>(
    updateTheme,
    hydrateTheme,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useListThemes(
  onSuccess?: (result: ThemeModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options, ThemeModel[], { data: ThemeResponse[] }>(
    listThemes,
    hydrateThemes,
    onSuccess,
    onError,
    onValidationError
  );
}

//todo: usePutApi ?
export function usePublishTheme(
  onSuccess?: (result: ThemeModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<PublishThemeData>, ThemeModel, ThemeResponse>(
    publishTheme,
    hydrateTheme,
    onSuccess,
    onError,
    onValidationError
  );
}

//todo: usePutApi ?
export function useUnpublishTheme(
  onSuccess?: (result: ThemeModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UnpublishThemeData>, ThemeModel, ThemeResponse>(
    unpublishTheme,
    hydrateTheme,
    onSuccess,
    onError,
    onValidationError
  );
}

//todo: usePutApi ?
export function useDeleteTheme(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<DeleteThemeData>, DefaultResponseModel, DefaultResponse>(
    deleteTheme,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}
