import styled from 'styled-components';

import Text from '@Components/atoms/Text';

export const Root = styled.div`
  margin-top: ${(props) => props.theme.space.large_32};
`;

export const StyledText = styled(Text)`
  font-weight: ${(props) => props.theme.font.text.light.medium.fontWeight};
  font-size: ${(props) => props.theme.font.text.light.small.fontSize};
  text-wrap: stable;
  color: ${(props) => props.theme.palette.footer.textLight};
  margin-top: ${(props) => props.theme.space.default_16};
  margin-bottom: ${(props) => props.theme.space.large_32};
`;
