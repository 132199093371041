import { IconTrash } from '@tabler/icons-react';
import React, { PointerEvent, useCallback, useEffect, useState } from 'react';

import { PropsWithClassName } from '@Components/helper';

import { DeleteButton, DeleteIcon, DeleteIconWrapper, Root, StyledIcon } from './DeleteSwiper.styles';

interface Props extends PropsWithClassName {
  children: React.ReactNode;
  onDelete: () => void;
  onClick: () => void;
}

const DeleteSwiper = (props: Props): React.ReactElement => {
  const { onDelete, onClick, children } = props;

  const [transition, setTransition] = useState('none');
  const [transform, setTransform] = useState('none');
  const [isDeleted, setIsDeleted] = useState(false);
  const [mouseUpListener, setMouseUpListener] = useState<{
    event: PointerEvent<HTMLDivElement>;
    active: boolean;
  } | null>(null);
  const [downX, setDownX] = useState(0);

  useEffect(() => {
    if (transition === 'none') {
      return;
    }

    const timeout = setTimeout(() => {
      setTransition('none');
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [transition]);

  const handleDelete = useCallback(() => {
    setTransition('all 200ms');
    setTransform('translate(-110%) scaleY(0)');
    setIsDeleted(true);
    onDelete();
  }, [onDelete]);

  const onPointerUp = useCallback(
    (event: PointerEvent<HTMLDivElement>) => {
      const previousDownX = downX;
      setDownX(0);

      if (event.clientX - previousDownX === 0) {
        // pointer has not moved => normal click
        return;
      }

      if (event.clientX - previousDownX < -20 && event.clientX - previousDownX > -150) {
        // pointer moved a bit to the left => slightly show delete button
        setTransition('all 200ms');
        setTransform('translate(-55px)');

        return;
      }

      if (event.clientX - previousDownX < -150) {
        // pointer moved a lot to the left => delete
        handleDelete();
        return;
      }

      // pointer moved to the right => reset to initial position
      setTransition('all 200ms');
      setTransform('none');
    },
    [downX, handleDelete]
  );

  useEffect(() => {
    if (!mouseUpListener || !mouseUpListener.active) {
      return;
    }

    const listener = () => onPointerUp(mouseUpListener.event);

    if (mouseUpListener.active) {
      window.addEventListener('mouseup', listener);
    }

    return () => window.removeEventListener('mouseup', listener);
  }, [mouseUpListener, onPointerUp]);

  const handleClick = () => {
    if (transition !== 'none') {
      return;
    }

    onClick();
  };

  const onPointerMove = (event: PointerEvent<HTMLDivElement>) => {
    if (downX === 0) {
      return;
    }

    const newX = event.clientX;

    if (newX - downX < -150) {
      // at a specific point move completely show delete button to use deletion on pointer up
      setTransition('all 200ms');
      setTransform('translate(-100%)');
      return;
    }

    if (transform === 'translate(-100%)') {
      // delete button was shown completely, but the user moved the pointer back to the right
      setTransition('all 200ms');
      setTransform(`translate(${newX - downX}px)`);
      return;
    }

    if (transition !== 'none') {
      return;
    }

    // move the element in sync with the pointer
    setTransform(`translate(${newX - downX}px)`);
  };

  const onPointerDown = (event: PointerEvent<HTMLDivElement>) => {
    if (transform === 'translate(-55px)') {
      setDownX(event.clientX + 55);
      return;
    }

    setDownX(event.clientX);
  };

  const onPointerLeave = (event: PointerEvent<HTMLDivElement>) => {
    if (downX === 0) {
      return;
    }

    // on desktop pointer up is not triggered if made out of element boundaries
    setMouseUpListener({ event, active: true });
  };

  const onPointerEnter = () => {
    if (downX === 0 || !mouseUpListener) {
      return;
    }

    setMouseUpListener({ event: mouseUpListener.event, active: false });
  };

  return (
    <Root
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerLeave={onPointerLeave}
      onPointerEnter={onPointerEnter}
      onClick={handleClick}
      $transform={transform}
      $transition={transition}
      $isDeleted={isDeleted}
      $hideDeleteIcon={transform !== 'none'}
      data-testid={props.dataTestId ?? 'delete-swiper-root'}
    >
      {children}
      <div>
        <DeleteIconWrapper
          $isHidden={transform !== 'none'}
          onClick={(event) => {
            event.stopPropagation();
            setTransition('all 200ms');
            setTransform('translate(-100%)');
            setTimeout(handleDelete, 200);
          }}
        >
          <DeleteIcon size={13} stroke={1} />
        </DeleteIconWrapper>
      </div>
      <DeleteButton
        onClick={(event) => {
          event.stopPropagation();
          handleDelete();
        }}
        data-testid={`${props.dataTestId ?? 'delete-swiper'}-delete-button`}
      >
        <StyledIcon icon={IconTrash} strokeWidth={1.5} />
      </DeleteButton>
    </Root>
  );
};
export default DeleteSwiper;
