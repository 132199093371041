import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { TextTransform } from '@Helpers/types/text';

import { Root } from './Link.styles';

export enum LinkWeight {
  Light = 'light',
  SemiBold = 'semibold',
}

export enum LinkSize {
  Xs = 'xs',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum LinkColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Link = 'link',
  Header = 'header',
}

interface Props extends PropsWithClassName {
  children: React.ReactNode;
  href: string;
  weight: LinkWeight;
  size: LinkSize;
  color?: LinkColor;
  hideUnderline?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  rel?: string;
  transform?: TextTransform;
  onClick?: () => void;
}

const Link = (props: Props): React.ReactElement => {
  const {
    weight,
    size,
    dataTestId,
    color = LinkColor.Link,
    href,
    children,
    hideUnderline = false,
    target,
    rel,
    transform = TextTransform.Unset,
    onClick,
  } = props;

  return (
    <Root
      href={href}
      passHref
      className={props.className}
      $weight={weight}
      $size={size}
      target={target}
      rel={rel}
      $color={color}
      data-testid={dataTestId ? dataTestId : 'Link-root'}
      $hideUnderline={hideUnderline}
      $transform={transform}
      onClick={onClick}
    >
      {children}
    </Root>
  );
};

export { Link };
