import { IconX } from '@tabler/icons-react';
import styled from 'styled-components';

import { Icon as IconComponent } from '@Components/atoms/Icon/Icon';

export const DeleteIcon = styled(IconX)`
  align-self: center;
  border: none;
  cursor: pointer;
  position: relative;
`;

export const Root = styled.div.attrs<{
  $transition: string;
  $transform: string;
  $isDeleted: boolean;
  $hideDeleteIcon: boolean;
}>((props) => ({
  style: {
    transition: props.$transition,
    transform: props.$transform,
    height: props.$isDeleted ? 0 : 'auto',
    padding: props.$isDeleted ? 0 : `${props.theme.space.xs_4} 0`,
  },
}))`
  display: flex;
  flex-direction: row;
  touch-action: pan-y;
  position: relative;
  transform-origin: top center;

  ${DeleteIcon} {
    visibility: hidden;
  }

  &:hover {
    ${DeleteIcon} {
      visibility: ${(props) => (props.$hideDeleteIcon ? 'hidden' : 'visible')};
    }
  }
`;

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${(props) => props.theme.device.desktop}) {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const DeleteIconWrapper = styled.div<{ $isHidden: boolean }>`
  position: relative;
  margin-top: 2px;
  margin-right: 2px;

  svg {
    stroke: ${(props) => props.theme.palette.text.secondary};
  }

  &:hover {
    &::before {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      content: ' ';
      background: ${(props) => (props.$isHidden ? 'none' : props.theme.palette.icon.background)};
    }
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  right: -110%;
  top: 0;
  width: 110%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.text.error};
  display: flex;
  align-items: center;
  padding-left: ${(props) => props.theme.space.default_16};
  border-radius: 8px;
`;

export const StyledIcon = styled(IconComponent)`
  color: ${(props) => props.theme.palette.badge.error.text};
`;
