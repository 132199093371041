import styled from 'styled-components';

interface StyledImageProps {
  src: string;
  width: number;
  height: number;
}

export const Root = styled.div``;

export const StyledImage = styled.div<StyledImageProps>`
  background: center center url(${(props) => props.src});
  background-size: contain;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;
