import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';
import Image from 'next/image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AchievementLevelUpModel from '@Api/models/NotificationMetaData/AchievementLevelUpModel';
import AchievementUnlockedNotificationModel from '@Api/models/NotificationMetaData/AchievementUnlockedNotificationModel';
import AntiCheatToUnlockResultModel from '@Api/models/NotificationMetaData/AntiCheatToUnlockResultModel';
import CompetitionResultNotificationModel from '@Api/models/NotificationMetaData/CompetitionResultNotificationModel';
import CompetitionWonModel from '@Api/models/NotificationMetaData/CompetitionWonModel';
import NotificationModel, { NotificationType } from '@Api/models/NotificationModel';

import DateTimeDistance from '@Components/atoms/DateTime/DateTimeDistance';
import IconCompetition from '@Components/atoms/Icons/IconCompetition/IconCompetition';
import IconLock from '@Components/atoms/Icons/IconLock';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import Text from '@Components/atoms/Text';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { getAchievementIcon, getProgressAchievementIcon } from '@Components/helper/achievement';
import { capitalizeFirstLetter } from '@Components/helper/language';
import { getDynamicSubRoute } from '@Components/helper/url';
import DeleteSwiper from '@Components/molecules/DeleteSwiper/DeleteSwiper';
import { Routes } from '@Components/Routes';

import useRouter from '@Helpers/i18n/useRouter';
import {
  AntiCheatGameResult,
  getLanguageFromAntiCheatGameResult,
  getTypingModeTranslationFromAntiCheatGameResult,
} from '@Helpers/result';
import { TextColor, TextSize } from '@Helpers/types/text';

import { iconStroke } from '@Assets/styles/theme/theme';

import { ContentContainer, Icon, Root } from './NotificationCenterRow.styles';

interface Props extends PropsWithClassName {
  handleCloseSidebar: () => void;
  notification: NotificationModel;
  onDeleteClick: (notificationId: string) => void;
  showAntiCheatModal: (result: AntiCheatGameResult) => void;
}

const getNotificationIcon = (notification: NotificationModel, isHeader: boolean = false) => {
  const notificationType = notification.notificationType;
  const metaData = notification.metaData;

  switch (notificationType) {
    case NotificationType.AntiCheatRequired_App:
      return <IconLock />;
    case NotificationType.CompetitionResult_App:
      return <IconCompetition isHeader={isHeader} />;
    case NotificationType.CompetitionWon_App:
      return <IconCompetition isHeader={isHeader} />;
    case NotificationType.ProgressAchievementUnlocked_App:
      if (metaData instanceof AchievementUnlockedNotificationModel) {
        return getProgressAchievementIcon(metaData.userAchievement.achievementType, isHeader);
      }
      break;
    case NotificationType.ProgressAchievementLevelUp_App:
      if (metaData instanceof AchievementLevelUpModel) {
        return getProgressAchievementIcon(metaData.userAchievement.achievementType, isHeader);
      }
      break;
    case NotificationType.RewardAchievementUnlocked_App:
      if (metaData instanceof AchievementUnlockedNotificationModel) {
        // todo: reward notifications styling
        return (
          <Image
            width={30}
            height={30}
            src={getAchievementIcon(metaData.userAchievement.achievementType)}
            alt={metaData.userAchievement.achievementType}
          />
        );
      }
      break;
    case NotificationType.UserIsCheater_App:
    case NotificationType.UserHasNonCompliantProfile_App:
    case NotificationType.UserHasNonCompliantUsername_App:
    case NotificationType.UserIsSpammer_App:
      return <IconAlertCircle strokeWidth={iconStroke} color="red" />;
    case NotificationType.RevokeUserIsCheater_App:
    case NotificationType.RevokeUserHasNonCompliantProfile_App:
    case NotificationType.RevokeUserHasNonCompliantUsername_App:
    case NotificationType.RevokeUserIsSpammer_App:
      return <IconCircleCheck strokeWidth={iconStroke} color="green" />;
    default:
      return <></>;
  }
};

const NotificationCenterRow = (props: Props): React.ReactElement => {
  const { dataTestId, notification, onDeleteClick, showAntiCheatModal, handleCloseSidebar } = props;

  const router = useRouter();
  const { user } = useGlobalContext();
  const { t } = useTranslation('global');

  const notificationType = notification.notificationType;
  const metaData = notification.metaData;

  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = () => {
    setIsDeleted(true);
    onDeleteClick(notification.id);
  };

  const handleClick = () => {
    if (handleCloseSidebar) {
      handleCloseSidebar();
    }

    switch (notificationType) {
      case NotificationType.AntiCheatRequired_App:
        if (metaData instanceof AntiCheatToUnlockResultModel) {
          showAntiCheatModal(metaData.result);
        }
        break;
      case NotificationType.CompetitionResult_App:
      case NotificationType.CompetitionWon_App:
        if (metaData instanceof CompetitionWonModel || metaData instanceof CompetitionResultNotificationModel) {
          router.push(getDynamicSubRoute(Routes.Competition, metaData.urlHash));
        }
        break;
      case NotificationType.ProgressAchievementLevelUp_App:
      case NotificationType.ProgressAchievementUnlocked_Toast:
      case NotificationType.ProgressAchievementLevelUp_Toast:
      case NotificationType.ProgressAchievementUnlocked_App:
      case NotificationType.RewardAchievementUnlocked_App:
      case NotificationType.RewardAchievementUnlocked_Toast:
        if (metaData instanceof AchievementUnlockedNotificationModel || metaData instanceof AchievementLevelUpModel) {
          router.push(
            `${getDynamicSubRoute(Routes.User, notification.user.username)}?highlightedAchievement=${
              metaData.userAchievement.achievementType
            }`
          );
        }
        break;
      case NotificationType.UserIsCheater_App:
      case NotificationType.UserIsSpammer_App:
        if (user) {
          // todo: redirect to blog or open modal with more information
          router.push(getDynamicSubRoute(Routes.User, user.username));
        }
        break;
      case NotificationType.UserHasNonCompliantProfile_App:
      case NotificationType.UserHasNonCompliantUsername_App:
        // todo: redirect to blog or open modal with more information
        router.push(Routes.SettingsProfile);
        break;
      default:
        return;
    }
  };

  const renderAntiCheatAdditionalInfo = () => {
    if (!(notification.metaData instanceof AntiCheatToUnlockResultModel)) {
      return <></>;
    }

    const { result } = notification.metaData;

    const languageName = capitalizeFirstLetter(getLanguageFromAntiCheatGameResult(result).languageNative);
    const typingMode = getTypingModeTranslationFromAntiCheatGameResult(result, t);
    const wpm = `${result.testResult.wpm} ${t('units.wpm.short')}`;

    return (
      <Text size={TextSize.Xs} color={TextColor.Header} bold>
        {`${languageName} / ${typingMode} / ${wpm}`}
      </Text>
    );
  };

  return (
    <Root className={props.className} data-testid={dataTestId ?? 'notification-center-row-root'} $isDeleted={isDeleted}>
      <DeleteSwiper onDelete={handleDelete} onClick={handleClick}>
        <Icon>{getNotificationIcon(notification, true)}</Icon>
        <ContentContainer>
          <Row noGutters xsBetween>
            <Row noGutters rowGap={RowGap.Small}>
              <Text size={TextSize.Xs} bold color={TextColor.Tertiary}>
                {notification.title}
              </Text>
              <Text size={TextSize.Xs} color={TextColor.Tertiary}>
                <DateTimeDistance distanceDate={notification.createdAt} />
              </Text>
            </Row>
          </Row>
          {renderAntiCheatAdditionalInfo()}
          <Text size={TextSize.Xs} color={TextColor.Header} bold={!notification.readAt}>
            {notification.message}
          </Text>
        </ContentContainer>
      </DeleteSwiper>
    </Root>
  );
};
export default NotificationCenterRow;
